import { Grid } from '@mui/material'
import React, {useState} from 'react'
import ContactForm from '../../components/Contact/ContactForm'
import ContactMap from '../../components/Contact/ContactMap'
import ContactInfo from '../../components/Contact/ContactInfo'
import axios from "axios";
import Swal from 'sweetalert2'
import ERROR from "../../assets/images/error.png"
import Notification from "../../components/Benefits/SuccessNotif"


const Contact = ({mairie,url}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const sendMessage =  (data) => {
    const contact = {
      message:data,
      mairie: mairie
    }
    setIsLoading(true)
    axios.post(`${url}/contact`, {contact})
          .then((response) => {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 2500
                })
                setIsLoading(false)
            })
          .catch(erreur => {
              setError(erreur);
              setIsLoading(false)
            });
  }
  if (error) return (<Notification image={ERROR} titre="Erreur !!" texte="Une erreur inattendu a été observé, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);
  return (
    <Grid container spacing={3} className='section pb-45'>
        <Grid item sm={12} md={6}>
            <ContactForm isLoading={isLoading} mairie={mairie} sendMessage={sendMessage}/>
        </Grid>
        <Grid item sm={12} md={6}>
            <ContactInfo mairie={mairie}/>
        </Grid>
        <Grid item sm={12}>
            <ContactMap/>
        </Grid>
    </Grid>
  )
}

export default Contact
