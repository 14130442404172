import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import './timeline.css'
import Typography from '@mui/material/Typography'

const CustomTimeline = ({title,icon,children}) => {
  return (
    <Timeline className={'timeline'}>
        {/* Header Item */}
      <TimelineItem className={'timeline-firstItem'}>
        <TimelineSeparator>
          <TimelineDot  className={'timeline-dot-header'}>
            {icon}
           </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant="h6" className={'timeline-header'}>{title}</Typography>
        </TimelineContent>
      </TimelineItem>
        {children}
    </Timeline>
  )
}

export const CustomTimelineSeparator = () => (
    <TimelineSeparator className={'separator-padding'}>
          <TimelineDot variant={'outlined'} className={'timeline-dot'}/>
          <TimelineConnector />
    </TimelineSeparator>
)


export default CustomTimeline
