import { Grid, TextField } from '@mui/material'
import React, {useEffect} from 'react'
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import  "./contact.css"


const ContactForm = ({sendMessage,isLoading}) => {

  const { register, handleSubmit,reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    sendMessage(data);
  };

  useEffect(() => {
    if (isLoading) {
      reset({ nom_complet: "", email: "", message: "" })
    }
  }, [isLoading])


  return (
    <>
         <Grid item className='section-title mb-30'>
            <span></span>
            <h6  className='section-title-text'>Formulaire de contact</h6>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}  className="ptb-30">
      
              <Grid item sm={12} xs={12} md={6}>
                <TextField id="outlined-basic" name='nom_complet' label="Prénom & nom" variant="outlined"
                          style={{width:"100%"}}  {...register("nom_complet", { required:true})}/>
                          {errors.nom_complet && errors.nom_complet.type === "required" && (
                            <small className="errorMsg">Prénom & nom est obligatoire.</small>
                          )}
              </Grid>
              <Grid item sm={12} xs={12} md={6} >
                <TextField id="outlined-basic" name='email' label="Email" variant="outlined" 
                            style={{width:"100%"}}  
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })}/>
                      {errors.email && errors.email.type === "required" && (
                          <small className="errorMsg">Email est obligatoire.</small>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <small className="errorMsg">Email est invalide.</small>
                        )}
              </Grid>
              <Grid item  className="ptb-30 textarea">
                <TextField
                    id="outlined-textarea"
                    label="Message"
                    placeholder="Saisir votre message"
                    multiline
                    rows={4}
                    style={{width:"100%"}}
                    name='message'
                    {...register("message",{required:true,minLength: 100})}
                  />
                  {errors.message && errors.message.type === "required" && (
                   <small className="errorMsg">Message est obligatoire.</small>
                  )}
                  {errors.message && errors.message.type === "minLength" && (
                    <small className="errorMsg">
                      Message est trop court.
                    </small>
                  )}
              </Grid>
              <Grid item className='ptb-30'>
                  <Button variant="contained" type='submit' endIcon={<SendIcon />}>
                    Envoyer
                    {isLoading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          position: 'absolute',
                          top: -6,
                          left: -6,
                          zIndex: 1,
                        }}
                      />
                    )}
                    </Button>
              </Grid>
           
        </Grid>
        </form>
    </>
  )
}

export default ContactForm
