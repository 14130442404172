import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
//import AccordeonReservation from '../../components/Reservation/AccordeonReservation'
import ReservationForm from "../../components/Reservation/Form/ReservationForm";
import InfoSalle from "../../components/Reservation/InfoSalle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import ERROR from "../../assets/images/error.png"
import Notification from "../../components/Benefits/SuccessNotif"

const ReservationConfirm = ({ url,mairie }) => {
  const navigate = useNavigate();
  const [localDate, setLocalDate] = useState();
  const [localId, setLocalId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [error, setError] = useState(null);
  const [infoSalle, setInfoSalle] = useState(null);
  const [array, setArray] = useState([]);
  const [type, setType] = useState("")
  const [isSubmit, setIsSubmit] = useState(false);


  useEffect(() => {
    setLocalDate(JSON.parse(sessionStorage.getItem("localDate")));
    setLocalId(JSON.parse(sessionStorage.getItem("localSalle")));
  },[])

  useEffect(() => {
   
    if (localId && localDate && localDate.lenght !== 0) {
      axios
        .get(url + "/option/getOptionSalle/" + localId)
        .then((response) => {
          setOption(response.data.options);
          setInfoSalle(response.data);
          setIsLoading(true);
        })
        .catch((erreur) => {
          setError(erreur);
          setIsLoading(true);
        });
       
        
    }
  }, [localId, url]);

  const chooseType = (type) => {
        setType(type)
  }

  /**
   * Add option data
   * @param {*} data 
   */
  const handleClick = (data) => {
    if (array.some((option) => option.id === data.id) === false) {
      const newArray = [...array, data];
      setArray(newArray);
    } else {
      setArray((current) => current.filter((option) => option.id !== data.id));
    }
  };
 
  

  /**
   * Delete date reserved
   * @param {*} elm 
   */
  const deleteReservation = (elm) => {
      const newCart = localDate.filter(
        (item) => !(item.id === elm )
      );
      sessionStorage.setItem("localDate", JSON.stringify(newCart));
      setLocalDate(JSON.parse(sessionStorage.getItem("localDate")))
      // verifier taille du tableau newcart !=0
      if (newCart === null || newCart.lenght === 0) {
        sessionStorage.removeItem("localDate")
      }
  }

  const TypeSubmit =  (data) => {

      if(data.type === "Résident" && data.user.code !== mairie.code){
        Swal.fire(
          'Oups?',
          "Désolez vous n'êtes pas un résident de cette mairie\n\n Merci de choisir l'option Non Résident",
          'warning'
        ) 
      } else {
      setLoading(true)
       axios.post(`${url}/reservation`, {data})
          .then((response) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              setIsSubmit(true)
              setLoading(false)
              sessionStorage.clear()
              navigate('/notification')
            })
          .catch(erreur => {
              setError(erreur);
              setIsSubmit(true)
              setLoading(false)
            });
      }
  };

  if (error) return (<Notification image={ERROR} titre="Erreur !!" texte="Une erreur inattendu a été observé, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);
  return (localId && localDate && localDate.lenght !== 0) ? (
    <Grid container spacing={2} className="section pb-45">
      <Grid item xs={12} md={9}>
        {/* <AccordeonReservation
         dataType={datas.type} options={option} addOption={handleClick} optionChoice={array}/> */}
        {isLoading ? (
          <ReservationForm
            options={option}
            addOption={handleClick}
            optionChoice={array}
            dateChoisi={localDate}
            addReservation={TypeSubmit}
            deleteReservation={deleteReservation}
            infoSalle={infoSalle}
            chooseType={chooseType}
            mairie= {mairie}
            loading={loading}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} md={3}>
        {isLoading ? (
            <InfoSalle
              dateChoisi={localDate}
              info={infoSalle}
              options={array}
              type={type}
            />
        ) : (
          "Chargement en cours"
        )}
      </Grid>
    </Grid>
  ) : (
    navigate("/reservation", { replace: true })
  );
};

export default ReservationConfirm;
