import React from 'react'
import "./reservationImage.css"
import { Card, CardContent, CardMedia } from '@mui/material'
import { Badge, ListGroup } from 'react-bootstrap'
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import COUVERTURE_SALLE from "../../assets/images/salle-inconnu.webp"
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const InfoSalle = ({dateChoisi,info,options,type}) => {

  /**
     * Count number of true value in date
     */

  function numberOfTrueValues(obj) {
    var counter = 0;
    for (var index = 0; index < obj.length; index++) {
       if (obj[index].week === true) {
          counter++;
       }
    }
    return counter;
 }
  /**
   * SUM PRIX OPTION
   */
  const totalOption = options.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.prix;
  }, 0);

  const totalReservation = dateChoisi.reduce((accumulator, currentValue) => {
    return accumulator + ((numberOfTrueValues(dateChoisi) > 1 && currentValue.week) ? info.prixWeekend : (type === "Résident" ? info.prixResident : info.prixNonResident));
  }, 0);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={info.couverture ? info.couverture : COUVERTURE_SALLE}
        title={info.nom}
      />
      <CardContent>
        <h6 className='titre-info mb-2'>
          {info.nom}
        </h6>
        {type && dateChoisi.length !== 0 && (
            <ListGroup as="ol" >
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start">
              <div className="ms-1 titre-info">
              Prix <Tooltip title="Le prix est succeptible de changer selon le weekend ou la localité" arrow>
                    <HelpOutlineRoundedIcon color='info'  fontSize='small'/>
                  </Tooltip></div>
              <Badge bg="primary" pill>{totalReservation}€</Badge>
            </ListGroup.Item>
            {options?.map((option,index) => (
              <ListGroup.Item key={index}
              as="li"
              className="d-flex justify-content-between align-items-start">
              <div className="truncate">
              {option.libelle}</div>
              <Badge bg="primary" pill>{option.prix}€</Badge>
              {/*<DeleteForeverIcon className='cursor-pointer' onClick={() => deleteById(option.id)} color='error' fontSize='small'/>*/}
            </ListGroup.Item>
            ))}
             <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start">
              <div className="ms-1 titre-info">
              <b className='titre-info'>Total</b></div>
              <Badge bg="dark" className='titre-info' pill>{totalReservation+totalOption}€</Badge>
            </ListGroup.Item>
          </ListGroup>
        )}
        
      </CardContent>
    </Card>
  )
}

export default InfoSalle
