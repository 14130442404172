import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./blogDetail.css"
import BlogInfo from '../../components/Blog/BlogInfo'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ERROR from "../../assets/images/error.png"
import Notification from "../../components/Benefits/SuccessNotif"

const BlogDetail = ({url}) => {

  const id = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${url}/post/detail/${id.id}`)
    .then((response) => {
        setPost(response.data);
        setIsLoading(true)
        console.log(post)
      })
    .catch(erreur => {
        setError(erreur);
        setIsLoading(true)
      });
       
}, []);

if (error) return (<Notification image={ERROR} titre="Erreur !!" texte="Une erreur inattendu a été observé, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);
  return (
    <Grid container  className="section pb-45 pt-45">
        <Grid item xs={12} md={12} className='blog-detail'>
            <BlogInfo post={post}/>
        </Grid>
        {/*<Grid item xs={12} md={4} className='blog-sidebar'>
            <BlogSidebar post={post.id}/>
      </Grid>*/}
    </Grid>
  )
}

export default BlogDetail
