import React, { useState } from 'react'
import { Grid } from '@mui/material'
import "./reservationImage.css";
import "../../utils/calendar.css"
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import LocalCalendar from "../../utils/myCustomLocaleCalendar";
import Alert from '@mui/material/Alert';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const CalendarReservation = ({salleId,dateReserver}) => {

    const navigate = useNavigate();
    const [arrayDate, setArrayDate] = useState([]);
    /**
        * GESTION DU CALENDRIER
    */
    const preselectedDays = []
    const disabledDays = dateReserver;

   
  
    function isWeekend(date = new Date()) {
        return date.getDay() === 6 || date.getDay() === 0;
      }
    const [selectedDayRange, setSelectedDayRange] = useState(preselectedDays);

    const saveDate = () => {
        sessionStorage.clear()
        setArrayDate([])
        // eslint-disable-next-line
        selectedDayRange.map((date,index) => {
            arrayDate.push({
                id:index+1,
                day:date.day,
                month:date.month,
                year:date.year,
                week:isWeekend(new Date(date.year+"-"+date.month+"-"+date.day))
             })  
        })
        
        sessionStorage.setItem("localDate", JSON.stringify(arrayDate))
        sessionStorage.setItem("localSalle", JSON.stringify(salleId))
        navigate('/reservation-validation')
    }

  return (
    <>
      <Grid item sm={12} xs={12} md={7} className='pt-35'> 
            <Calendar
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                disabledDays={disabledDays}
                minimumDate={utils().getToday()}
                calendarClassName={'responsive-calendar'}
                shouldHighlightWeekends
                locale={LocalCalendar}
            />
        </Grid>
        <Grid item sm={12} xs={12} md={5} className='pt-35 flexCenter'>
            <div className='flexCenterBis'>
                <div>
                <Alert severity="warning">
                    Si vous souhaitez réserver le weekend n’oubliez pas de cocher le samedi et le dimanche
                </Alert>
                    <div className='legende'>
                        <span className='rondRed'></span> <span className='textRond'>Non disponible</span>
                    </div>
                </div>
                <div className='flexCenterTris'>
                    {
                        selectedDayRange.length > 0 && 
                        <>
                            <ListGroup className='mt-3'>
                                { selectedDayRange.map((value,index) => (
                                    <ListGroup.Item key={index} className='center anim'>{value.day+"/"+((value.month) < 10 ? "0"+value.month : value.month) +"/"+value.year}</ListGroup.Item>
                                    ))}
                            </ListGroup>
                            <button className="custom-btn" onClick={saveDate}>
                            <span className="btn-text">Réserver { (selectedDayRange.length > 1) ? 'les dates' : 'la date'}</span>
                        </button>
                        </>
                        
                    }
                    
                </div>
                
            </div>
        </Grid>
    </>
  )
}

export default CalendarReservation
