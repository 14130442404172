import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { HomeRounded } from '@mui/icons-material';
import {useLocation} from 'react-router-dom';
import CutomButton from '../Button/CutomButton';
import TelegramIcon from '@mui/icons-material/Telegram';
import "./header.css"

const Header = () => {
  const location = useLocation().pathname;

  const handleCollapse = (e) => {
        var nav = document.getElementById("navbarNav");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
  }
    

  return (
    <Navbar expand="lg"  className='header'>
        <Nav.Link as={NavLink} to="/" className='header-navlink'>
            <Navbar.Brand className='header-home'>
              <HomeRounded/>
            </Navbar.Brand> 
        </Nav.Link>

        <Navbar.Toggle id="navbarBtn"/>

        <Navbar.Collapse id="navbarNav">
          <Nav className='header-left'>
          <Nav.Link as={NavLink} to='/actualites' onClick={handleCollapse} className={location === '/actualites' ? 'header-link-active' : 'header-link'}>
              Actualités
            </Nav.Link>
            <Nav.Link as={NavLink} to='/calendrier' onClick={handleCollapse} className={location === '/calendrier' ? 'header-link-active' : 'header-link'}>
             Calendriers
            </Nav.Link>
            <Nav.Link as={NavLink} to='/reservation' onClick={handleCollapse} className={location === '/reservation' ? 'header-link-active' : 'header-link'}>
              Réservation de salle
            </Nav.Link>
          </Nav>
          <div className='header-right'>
          {/* Object.keys(resumeData.socials).map(key => (
            <a key={key} href={resumeData.socials[key].link} target='_blank' rel="noreferrer" >{resumeData.socials[key].icon}</a>
          )) */}
         
          
         <Link to='/contact'> 
                <CutomButton text={'Contactez-nous'} icon={<TelegramIcon/>}/>
              </Link>
          
        </div>
        </Navbar.Collapse>

       
    </Navbar>
  )
}

export default Header
