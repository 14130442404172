import { Grid } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React from 'react'
import  "./contact.css"

const ContactInfo = ({mairie}) => {
  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };
  return (
    <>
         <Grid item className='section-title mb-30'>
            <span></span>
            <h6  className='section-title-text'>Informations de contact</h6>
        </Grid>
        <Grid container   className="ptb-20">
            <Grid item className="info">
                <List sx={style} component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <ListItemText primary="Adresse" secondary={mairie.adresse} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Téléphone" secondary={mairie.phone} />
                  </ListItem>
                  <Divider />
                  <ListItem >
                    <ListItemText primary="Email" secondary={mairie.email}/>
                  </ListItem>
                  <Divider />
                </List>
            </Grid>
        </Grid>
    </>
  )
}

export default ContactInfo
