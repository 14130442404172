import React from 'react'
import Notification from "../../components/Benefits/SuccessNotif";
import SUCCESS from "../../assets/images/success.png"
const Success = () => {
    return (
        <div style={{display:'flex',justifyContent:'center'}}>
            <Notification image={SUCCESS} titre="Réussi !!" texte="Votre réservation a bien été bien enregistré avec succés. Un mail contenant les détails de la réservation et un lien  permettant le 
                suivi des étapes de validation vous a été envoyé par mail."/>
        </div>
        
    )
}

export default Success
