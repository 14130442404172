import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Header from './components/Header/Header';
import Profile from './components/Profile/Profile';
import Footer from './components/Footer/Footer';
import Routes from './routes/Routes';
import {BrowserRouter} from 'react-router-dom'
import Sticky from 'react-sticky-el';
import './App.css'
import { useState,useEffect, createContext } from 'react';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';
import ERROR from "./assets/images/down.png"
import Notification from "./components/Benefits/SuccessNotif"
export const CartContext = createContext(null);

function App() {
  // Online server
  const baseURL = "https://api-local-click.fr/api/v1"
  //local server
  //const baseURL = "http://localhost:8080/api/v1"
  const url = window.location.href;
  let domain = (new URL(url));
  const hostname = domain.hostname.replace('www.','');
  const [loading,setLoading] = useState(false)
  const [open,setOpen] = useState(true)
  const [mairie,setMairie] = useState(null)
  const [error, setError] = useState(null);
  //create cart state 


  var hours = 10; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  

  const clearValidation = (hours) => {
    let now = new Date().getTime();
    let setupTime = sessionStorage.getItem('setupTime');
    if (setupTime == null) {
      sessionStorage.setItem('setupTime', now)
    } else {
      if(now-setupTime > hours*60*60*1000) {
        sessionStorage.clear()
        sessionStorage.setItem('setupTime', now);
      }
  }
  }
  useEffect(() =>{
    clearValidation(1)
  },[])
  
  useEffect(() => {
    axios.get(`${baseURL}/etablissement/host/${hostname}`)
          .then((response) => {
              setMairie(response.data);
              setOpen(false)
              setLoading(true)
            })
          .catch(erreur => {
              setError(erreur);
              setLoading(true)
            });
    
  }, [setMairie,setLoading,setError,setOpen,hostname,baseURL]);

  if (error) return (<Notification image={ERROR} titre="Erreur server !!" texte="L'application n'arrive pas à contacter le serveur, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);

  return (
    <Container className='top-15'>
      
      {loading ? (
          <BrowserRouter>
             <Header/>
              <Grid container spacing={7}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                <Sticky >
                  <>
                    <Profile mairie={mairie}/>
                  </>
                  
                </Sticky>
                </Grid>
                <Grid item xs>
                    <div className='main-content container-shadow'>
                    <Routes mairie={mairie} url={baseURL}/>
                    </div>
                  <Footer mairie={mairie}/>
                </Grid>
              </Grid>
          </BrowserRouter>)
      : (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
      )}
      
    </Container>
  );
}

export default App;
