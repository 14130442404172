import React from 'react'
import "./salle.css"
import { useNavigate } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import COUVERTURE_SALLE from "../../assets/images/salle-inconnu.webp"

const Salle = ({ item }) => {

    const navigate = useNavigate();
    return (
        <Card variant="outlined" >
            <div>
                <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                    {item.nom}
                </Typography>
                <Typography level="body2">Nombre de place max  : <span style={{ color: 'black', fontWeight: 'bold' }}>{item.nombrePlace}</span></Typography>
            </div>
            <AspectRatio minHeight="120px" maxHeight="200px">
                <img
                    src={item.couverture ? item.couverture : COUVERTURE_SALLE}
                    srcSet={item.couverture ? item.couverture : COUVERTURE_SALLE}
                    loading="lazy"
                    alt={item.nom}
                />
            </AspectRatio>
            <CardContent orientation="horizontal">
                <div>
                    <Typography level="body3">À partir de :</Typography>
                    <Typography fontSize="md" fontWeight="md">
                        {item.prixNonResident}€
                    </Typography>
                </div>
                <Button
                    onClick={() => navigate('/reservation-detail/' + item.slug_salle)}
                    variant="solid"
                    size="sm"
                    color="primary"
                    aria-label={item.nom}
                    sx={{ ml: 'auto', fontWeight: 600 }}
                >
                    Plus de détails
                </Button>
            </CardContent>
        </Card>
    );
}

export default Salle
