import React from 'react'
import {Routes as Router , Route} from 'react-router-dom'
import Reservation from "../pages/Reservation/Reservation";
import Home from "../pages/Home/Home";
import Calendrier from "../pages/Calendrier/Calendrier";
import Contact from "../pages/Contact/Contact";
import Blog from "../pages/Blog/Blog";
import BlogDetail from '../pages/BlogDetail/BlogDetail';
import ReservationDetail from '../pages/ReservationDetail/ReservationDetail';
import ReservationValidation from '../pages/ReservationDetail/ReservationValidation';
import Success from '../pages/Notification/success'
import NOTFOUND from "../assets/images/notFound.png"
import Notification from "../components/Benefits/SuccessNotif"

const Routes = ({mairie,url}) => {
  return (
   
    <>
     
        <Router>
            <Route path='/calendrier' element={<Calendrier mairie={mairie.id}  url={url}/>} />
            <Route path='/contact' element={<Contact mairie={mairie} url={url}/>} />
            <Route path='/reservation' element={<Reservation mairie={mairie.id} url={url}/>} />
            <Route path='/reservation-detail/:id' element={<ReservationDetail url={url} mairie={mairie.id} />} />
            <Route path='/actualites' element={<Blog mairie={mairie.id} url={url}/>} />
            <Route path='/actualite/:id' element={<BlogDetail url={url} mairie={mairie.id} />} />
            <Route path='/reservation-validation' element={<ReservationValidation mairie={mairie}  url={url}/>} />
            <Route path='/' element={<Home mairie={mairie.id} url={url}/>} />
            <Route path='/notification' element={<Success/>} />
            <Route path='*' element={<Notification image={NOTFOUND} titre="Page introuvable !!" texte="Nous n'arrivons pas à trouver la page que vous recherchez. Veuillez vérifier l'URL pour toute faute de frappe."/>} />
        </Router> 
    </>
  )
}

export default Routes
