import React, { useState } from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grow,
    Skeleton,
    Typography,
    Alert,
    Grid,
  } from "@mui/material";
 
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import { ModalDialog, ModalOverflow } from "@mui/joy";
import Image from 'mui-image'
import "./momento.css"
import "../../pages/Calendrier/calendrier.css";

const Cards = ({item,isLoading}) => {
const [projectDialog, setProjectDialog] = useState(false);
  return (
    <>
              <Grow in timeout={1000}>
                <Card className="customCard" onClick={() => setProjectDialog(item)}>
                  <CardActionArea>
                    {isLoading ? (
                      <CardMedia
                        className="customCard-image"
                        image={item.image}
                        title={item.name}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangle"
                        animation="wave"
                        width={350}
                        height={140}
                      />
                    )}
                    <CardContent>
                      {isLoading ? (
                        <>
                        <Divider>{item.type}</Divider>
                        
                          <Typography
                            variant={"body2"}
                            className="customCard-title"
                          >
                            {item.name}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Skeleton animation="wave" width="80%" height={40} style={{marginBottom: 6}}/>
                          <Skeleton animation="wave" width="30%" height={40}/>
                        </>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grow>
              {projectDialog &&
              <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={projectDialog}
                    onClose={() => setProjectDialog(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    
                  >
                  <ModalOverflow>
                      <ModalDialog layout="fullscreen" color="primary" style={{ display: 'flex', alignItems: 'center' }}>
                          <ModalClose variant="outlined" />
                          <div style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'30px 0',maxWidth:'600px'}}>
                            <Grid item>
                            <Typography className="calendar-titre" style={{ marginBottom:'10px',fontSize:'30px',fontWeight:'bold'}}>{projectDialog.name}</Typography >
                            <Typography variant="body2" className='calendar-date' style={{ marginBottom:'20px'}}>{projectDialog.start} au {projectDialog.end}</Typography>
                            <Image src={projectDialog.image} alt=""  height="60vh" />
                            <div  style={{ marginTop:'20px',textAlign:'justify',fontSize:'16px',fontWeight:'400',color:'black'}}>{projectDialog.content}</div>
                            <Alert variant="outlined" style={{ marginTop:'20px'}}>Nombre de place maximal autorisé : {projectDialog.nbrMax}</Alert>
                            </Grid>
                          </div>
                        
                
                      </ModalDialog>
                  </ModalOverflow>
                
              </Modal>
              }
    </>
  )
}

export default Cards
