import React from 'react'
import Button from '@mui/material/Button';
import "./button.css"

const CutomButton = ({text, icon}) => {
  return (
   
    <Button 
        className={"custom-btn"}
        endIcon = {icon ? <div className='btn-icon-container'>{icon}</div> : null}
    >
        <span className='btn-text'>{text}</span>
    </Button>
  )
}

export default CutomButton
