import React from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './sliderActu.css'
import { Link } from 'react-router-dom';

const SliderActu = ({slide}) => {

      
  return (
    <Slider className="slider" autoplay={1000}>
        {slide.map((item, index) => (
            <Link
                key={index}
                style={{ background: `url('${item.image}') no-repeat center center`,backgroundSize: 'cover'  }}
                className='center'
                to={"/actualite/"+item.slug }
              >
                <div className='titre'>
                  <h3>{item.titre}</h3>
                </div>
               
          </Link>
        ))}
    </Slider>
  )
}

export default SliderActu
