import React, { useEffect } from 'react'
import Image from 'mui-image'
import { Grid, Typography } from '@mui/material'


const BlogInfo = ({post}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, []);
  return (
    <>
    <Grid item >
        <Image src={post.image}  />
    </Grid>
    <Grid item >
    <Typography variant="h1">
        {post.titre}
      </Typography>
      <Typography variant="body2" className='blog-description'>
       {post.description}
      </Typography>
    </Grid>
     
      
    </>
  )
}

export default BlogInfo
