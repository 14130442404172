import React, { useEffect, useState } from 'react'
import Salle from '../../components/Salle/Salle'
import { Grid } from '@mui/material'
import axios from 'axios'
import ERROR from "../../assets/images/error.png"
import Notification from "../../components/Benefits/SuccessNotif"

const Reservation = ({mairie,url}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [salles, setSalles] = useState([]);
  const [error, setError] = useState(null);
  

  useEffect(() => {
      axios.get(`${url}/salle/all/${mairie}`)
      .then((response) => {
          setSalles(response.data);
          setIsLoading(true)
        })
      .catch(erreur => {
          setError(erreur);
          setIsLoading(true)
        });
  }, [setIsLoading,setSalles,setError,mairie,url]);

 

  if (error) return (<Notification image={ERROR} titre="Erreur !!" texte="Une erreur inattendu a été observé, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);
  return (
    <>
    <Grid container spacing={1} className="section pb-20 pt-45">
            {/* Title */}
            <Grid item className="section-title mb-30">
            <span></span>
            <h6 className="section-title-text">Réservation de salle</h6>
            </Grid>    
    </Grid>
     <Grid container spacing={2} >
     {isLoading ?
        (salles?.map((item,index) =>(
          <Grid item md={6} key={index}>
            <Salle  item={item} />
          </Grid>
        )))
        : "En attente"
     }
   </Grid>
   </>
  )
}

export default Reservation
