import React from "react";
import "./benefits.css"


const SuccessNotif = ({image,titre,texte}) => {


  return (
        <div>
            <div style={{display:'flex',justifyContent:'center'}}>
                <img src={image} style={{ width:'60vh'}} alt="Réservation réussi" />
            </div>
            <div className="desc">
                <h1 className="titre-desc">{titre}</h1>
                <p className='text-desc'>{texte}</p>
            </div>
        </div>
  )
}

export default SuccessNotif
