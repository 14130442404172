export default {
    name: 'Mairie de Lyon',
    code: 21130,
    email: 'amath88@gmail.com',
    address: '1 rue Davault',
    phone: '33 (0)4 72 10 30 30',
    about:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae efficitur nibh, ac imperdiet massa. Phasellus rhoncus cursus tempor. Mauris lacinia leo id dui viverra, at pellentesque felis condimentum. Suspendisse potenti. Aenean eleifend at felis scelerisque eleifend. Maecenas mollis, mi sed placerat rutrum, mauris tellus cursus purus, vel dapibus urna felis eget est. Duis tempor quam id orci venenatis pulvinar. Aliquam suscipit vel ex et fringilla. Suspendisse congue nisi venenatis pulvinar tempus. Nam quis justo nunc. Aliquam viverra condimentum mattis.\n \nDuis quis vestibulum sem, in feugiat ex. Etiam dignissim viverra faucibus. Nullam pretium posuere tortor sed laoreet. Praesent mi purus, blandit non erat ac, venenatis facilisis odio. Proin sit amet neque non leo tincidunt rhoncus. In vulputate elit vitae tristique consequat. Ut ac quam lacus. Nunc suscipit pulvinar nisl, quis elementum dolor bibendum at.",
    horaire:[
        {
            jour: 'Lundi',
            heured: '08:00',
            heuref: '17:00'
        },
        {
            jour: 'Mardi',
            heured: '08:00',
            heuref: '17:00'
        },
        {
            jour: 'Mercredi',
            heured: '08:00',
            heuref: '17:00'
        },
        {
            jour: 'Jeudi',
            heured: '08:00',
            heuref: '17:00'
        },
        {
            jour: 'Vendredi',
            heured: '08:00',
            heuref: '17:00'
        }
    ],

    numero:[
        {
            title: "Samu",
            description: "15"
        },
        {
            title: 'Police de secours',
            description: '17'
        },
        {
            title: 'Sapeurs pompiers',
            description: '18'
        },
        {
            title: 'Infos Escroqueries',
            description: '0 805 80 58 17'
        },
        {
            title: 'Enfance en danger',
            description: '119'
        }
    ],
    salle : [
        {
            id:1,
            nom:'Salles A et B',
            slug_salle:'salles-a-b',
            nombrePlace:20,
            surface:"100m2",
            couverture:"https://media.abcsalles.com/images/1/salles/960x640/25021/la-palmeraie-9.jpg",
            prixResident:259.9,
            pictures: [
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-1.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-2.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-3.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-4.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-5.jpg"
              ],
        },
        {
            id:2,
            nom:'Salles lacombe',
            slug_salle:'salles-lacombe',
            nombrePlace:25,
            surface:"100m2",
            couverture:"https://media.abcsalles.com/images/1/salles/900h/25021/la-palmeraie-10.jpg",
            prixResident:359.9,
            pictures: [
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-1.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-2.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-3.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-4.jpg",
                "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/accommodation-20-5.jpg"
              ],
        },
    ],
    portofolio : [
        {
            type : 'Officiels',
            image: 'https://www.team4development.fr/wp-content/uploads/2015/07/Seminaire-1.jpg',
            name: 'Team building & séminaire',
            slug: 'officiel-project-1',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "Ça y est c’est le moment de rassembler toutes vos équipes, pour atteindre la première marche du podium. Lancez-vous dans une aventure entre collègues pour une meilleure cohésion interne. Organiser un Team Learning d’entreprise vous permet de réunir tous vos salariés autour d’un événement commun. Partagez vos valeurs et votre empreinte de marque avec eux, pour vous sentir liés. Vos séminaires incentive seront très bénéfiques pour tisser des liens entre vos employés. Ces événements, sous le signe de la bonne humeur et de l’amusement, sont très attendus par tous les membres de votre entreprise.",
        },
        {
            type : 'Festivités',
            image: 'https://idf-invest-territoires.fr/wp-content/uploads/2023/04/VESTACK-035.webp',
            name: 'Inauguration & lancement',
            slug: 'festivite-project-1',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:25,
            content: "Votre marque lance un nouveau produit ? C’est le moment idéal pour réunir tous vos clients et prospects. Lancez une action d’acquisition et de fidélisation auprès de vos clients actuels et de vos prospects. Organisez une soirée à votre image pour dévoiler votre nouveau produit. Créez le mystère et l’envie autour de celui-ci ! Un événement qui fera forcément parler de lui. Profitez de cette occasion pour vous sentir plus proche et plus à l’écoute de vos clients.",
        },
        {
            type : 'Officiels',
            image: 'https://www.agence-evenementielle-innovevents.fr/wp-content/uploads/2022/11/soiree-entreprise-nantes-44.jpg',
            name: 'Cocktail & déambulation',
            slug: 'officiel-project-2',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "C’est lors de vos événements que vous pourrez vous démarquer auprès de vos clients. Optez pour une soirée cocktail pour réunir tous vos clients actuels et potentiels. Partagez vos valeurs et votre image de prestige avec un événement à la hauteur. Montrez à vos clients que vous savez les chouchouter. Une véritable action de fidélisation et d’acquisition en une seule soirée. Faites parler de votre marque en organisant un événement cocktail des plus luxueux. Mesdames sortez les robes de soirées, ce soir vous brillerez de mille feux.",
            
        },
        {
            type : 'Scolaire',
            image: 'https://images.rtl.fr/~c/1155v769/rtl/www/1635851-les-logiciels-de-la-startup-evidenceb-vont-aider-les-eleves-de-seconde-a-ne-pas-decrocher.jpg',
            name: "le logiciel d'intelligence artificielle qui va aider les lycéens à la rentrée",
            slug: 'scolaire-project-1',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "L'Éducation nationale mise sur l'intelligence artificielle pour lutter contre le décrochage scolaire. Peu après la publication du dernier classement PISA, qui a consacré le déclin des élèves français en mathématiques par rapport aux autres pays développés, le locataire de la rue de Grenelle, Gabriel Attal, a annoncé des réformes pour impulser un choc des savoirs et remettre de l'exigence à l'école. Parmi ces mesures, annoncées au personnel de l'éducation nationale dans un courrier ce mardi 5 décembre, le ministre entend notamment renforcer l'enseignement des mathématiques et du français à l'aide d'un outil basé sur l'intelligence artificielle acquis par le ministère en 2020.",
        },
        {
            type : 'Scolaire',
            image: 'https://www.stanislas-cannes.com/images/APEL/2018-2019/20190520_kermesse.jpg',
            name: 'Comment organiser une kermesse dans son école ?',
            slug: 'scolaire-project-2',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "Avant tout, pour organiser une kermesse scolaire, l’association doit obtenir l’accord du chef d’établissement. Autrement dit, au directeur académique des services de l’éducation nationale (DASEN) qui doit être informé de toute l’organisation. Mais, elle doit également effectuer une demande d’autorisation auprès de la mairie. Cette déclaration doit ainsi indiquer entre autres le lieu de déroulement de la kermesse, la date et les heures du début et de fin de la manifestation, les activités envisagées, les mesures de prévention et de sécurité prévues… Conformément au circulaire n° 93-294 du 15 octobre 1993 sur l’utilisation des locaux scolaires par les associations en dehors des heures de formation, la mairie doit réaliser deux formalités avant de pouvoir donner son approbation :",
        }
    ],
    calendar : [
        {
            type : 'Officiels',
            image: 'https://www.team4development.fr/wp-content/uploads/2015/07/Seminaire-1.jpg',
            name: 'Team building & séminaire',
            slug: 'officiel-project-1',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "Ça y est c’est le moment de rassembler toutes vos équipes, pour atteindre la première marche du podium. Lancez-vous dans une aventure entre collègues pour une meilleure cohésion interne. Organiser un Team Learning d’entreprise vous permet de réunir tous vos salariés autour d’un événement commun. Partagez vos valeurs et votre empreinte de marque avec eux, pour vous sentir liés. Vos séminaires incentive seront très bénéfiques pour tisser des liens entre vos employés. Ces événements, sous le signe de la bonne humeur et de l’amusement, sont très attendus par tous les membres de votre entreprise.",
        },
        {
            type : 'Festivités',
            image: 'https://idf-invest-territoires.fr/wp-content/uploads/2023/04/VESTACK-035.webp',
            name: 'Inauguration & lancement',
            slug: 'festivite-project-1',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:25,
            content: "Votre marque lance un nouveau produit ? C’est le moment idéal pour réunir tous vos clients et prospects. Lancez une action d’acquisition et de fidélisation auprès de vos clients actuels et de vos prospects. Organisez une soirée à votre image pour dévoiler votre nouveau produit. Créez le mystère et l’envie autour de celui-ci ! Un événement qui fera forcément parler de lui. Profitez de cette occasion pour vous sentir plus proche et plus à l’écoute de vos clients.",
        },
        {
            type : 'Officiels',
            image: 'https://www.agence-evenementielle-innovevents.fr/wp-content/uploads/2022/11/soiree-entreprise-nantes-44.jpg',
            name: 'Cocktail & déambulation',
            slug: 'officiel-project-2',
            start: '20/06/2024',
            end: '30/06/2024',
            nbrMax:20,
            content: "C’est lors de vos événements que vous pourrez vous démarquer auprès de vos clients. Optez pour une soirée cocktail pour réunir tous vos clients actuels et potentiels. Partagez vos valeurs et votre image de prestige avec un événement à la hauteur. Montrez à vos clients que vous savez les chouchouter. Une véritable action de fidélisation et d’acquisition en une seule soirée. Faites parler de votre marque en organisant un événement cocktail des plus luxueux. Mesdames sortez les robes de soirées, ce soir vous brillerez de mille feux.",
            
        }
    ],
    blog:[
        {
            title:"See my current workspace lorem ipsum olsder froit fot des comment des cretin",
            image: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_640.jpg",
            slug:"see-my-current-workspace",
            date: "12/02/2023 11:20",
            category:"Loisirs"
        },
        {
            title:"How to become a web designer?",
            image: "https://plugins-media.makeupar.com/smb/blog/post/2023-02-15/4f9d578e-d540-4622-9874-7688db3c4044.jpg",
            slug:"how-to-become-a-web-designer",
            date: "12/02/2023 11:20",
            category:"Enfance"
        },
        {
            title:"Why is it better to work nights?",
            image: "https://imgv3.fotor.com/images/videoImage/Change-image-background-online-in-seconds-with-Fotors-AI-background-changer.jpg",
            slug:"why-is-it-better-to-work-nights",
            date: "12/02/2023 11:20",
            category:"Sénior"
        },
    ],
    socials: {
       /* Facebook: {
            link: 'https://www.facebook.com',
            text: 'AmathCoding',
            icon: <FacebookIcon/>
        },
        Twitter: {
            link: 'https://www.facebook.com',
            text: 'AmathCoding',
            icon: <TwitterIcon/>
        },
        LinkedIn: {
            link: 'https://www.facebook.com',
            text: 'AmathCoding',
            icon: <LinkedInIcon/>
        },
        Github: {
            link: 'https://www.facebook.com',
            text: 'AmathCoding',
            icon: <GitHubIcon/>
        }*/
    }
}