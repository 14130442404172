
import React, { useEffect, useState } from "react";
import Cards from "../../components/Cards/Cards";
import axios from 'axios';
import {
  Grid
} from "@mui/material";

const Blog = ({mairie,url}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
      axios.get(`${url}/post/all/${mairie}`)
      .then((response) => {
          setBlog(response.data);
          setIsLoading(true)
        })
      .catch(erreur => {
          setError(erreur);
          setIsLoading(true)
        });
  }, [setIsLoading,setBlog,setError,mairie,url]);

  if (error) return `Error: ${error.message}`;

  return (
    <Grid container spacing={1} className="section pb-45 pt-45">
      {/* Title */}
      <Grid item className="section-title mb-30">
        <span></span>
        <h6 className="section-title-text">Nos actualités</h6>
      </Grid>

      {/* projects */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {blog?.map((item,index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                <Cards item={item} isLoading={isLoading}/>
            </Grid>
           
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blog;
