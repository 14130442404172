import React, { useEffect, useState } from 'react'
import Momento from '../../components/Momento/Momento'
import CustomTimeline, {CustomTimelineSeparator} from '../../components/Timeline/CustomTimeline'
import { Grid, Skeleton, Typography } from '@mui/material'
import resumeData from '../../utils/resumeData'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimelineContent, TimelineItem } from '@mui/lab'
import SliderActu from '../../components/SliderActu/SliderActu'
import "./home.css"
import axios from 'axios'
import ERROR from "../../assets/images/error.png"
import Notification from "../../components/Benefits/SuccessNotif"


const Resume = ({mairie,url}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [slide, setSlide] = useState([]);
  const [error, setError] = useState(null);
    
      useEffect(() => {
          axios.get(`${url}/post/une/${mairie}`)
          .then((response) => {
            setSlide(response.data);
            setIsLoading(true);
            })
          .catch(erreur => {
              setError(erreur);
              setIsLoading(false);
            });
      }, [setIsLoading,setSlide,setError,mairie,url]);
    
      if (error) return (<Notification image={ERROR} titre="Erreur !!" texte="Une erreur inattendu a été observé, merci de rééssayer et si le problème persiste merci de contacter l'administrateur"/>);

  {/*useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);*/}
  return (
    <>
      {/* About me */}
      <Grid container className='section pb-45'>
        <Grid item className='section-title mb-30'>
            <span></span>
            <h6  className='section-title-text'>A la une</h6>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
                      <SliderActu slide={slide} />
                    ) : (
                      <Skeleton
                        variant="rectangle"
                        animation="wave"
                        width="100%"
                        height={350}
                      />
                    )}
        </Grid>
      </Grid>

      {/* Education and experience */}
      <Grid container className='section pb-45'>
        <Grid item className='section-title mb-30'>
            <span></span>
            <h6  className='section-title-text'>En ce moment</h6>
        </Grid>

        <Grid item xs={12}>
        <Grid container spacing={3}>
          {resumeData.calendar.map((item,index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                <Momento item={item} isLoading={isLoading}/>
            </Grid>
           
          ))}
        </Grid>
      </Grid>
      </Grid>

      {/* Horaire and Number utils */}
      <Grid container className='section'> 
         {/* Horaire */}
         <Grid item sm={12} md={6}>
                <CustomTimeline title="Horaire d'ouvertutre" icon={<AccessTimeIcon/>}>
                {resumeData.horaire.map((horaire,index) => (
                  <TimelineItem key={index}>
                    <CustomTimelineSeparator/>
                    <TimelineContent className='timeline-content'>
                      <Typography className='timeline-title'> <span className='fz-16'>{horaire.jour}</span></Typography>
                      <Typography variant='caption' className='timeline-date'><span className='color-primary fz-14'>{horaire.heured+" - "+horaire.heuref}</span></Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </CustomTimeline>
          </Grid>

            {/* Numero utiles */}
            <Grid item sm={12} md={6}>
                <CustomTimeline title="Numéros utiles" icon={<ContactPhoneIcon/>}>
                {resumeData.numero.map((numero,index) => (
                  <TimelineItem key={index}>
                    <CustomTimelineSeparator/>
                    <TimelineContent className='timeline-content'>
                      <Typography className='timeline-title'> <span className='fz-16'>{numero.title}</span></Typography>
                      <Typography variant='caption' className='timeline-date'><span className='color-primary fz-14'>{numero.description}</span></Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </CustomTimeline>
          </Grid>
      </Grid>
    </>
  )
}

export default Resume
