import React from 'react'
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grow,
    Skeleton,
    Typography,
  } from "@mui/material";
import "./Cards.css"
import couv from "../../assets/images/couv.webp"
import { Link } from 'react-router-dom';
import Moment from 'moment';

const Cards = ({item,isLoading}) => {
  return (
    <>
              <Grow in timeout={1000}>
                <Link to={"/actualite/"+item.slug}>
                <Card className="customCard">
                  <CardActionArea>
                    {isLoading ? (
                      item.image==="" ? (
                        <CardMedia
                        className="customCard-image"
                        image={couv}
                        title={item.titre}
                      />) : 
                      <CardMedia
                        className="customCard-image"
                        image={item.image}
                        title={item.titre}
                      />  
                    ) : (
                      <Skeleton
                        variant="rectangle"
                        animation="wave"
                        width={350}
                        height={140}
                      />
                    )}
                    <CardContent>
                      {isLoading ? (
                        <>
                          <Typography
                            variant={"body2"}
                            className="customCard-title"
                          >
                            {item.titre}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="customCard-description"
                          >
                            {Moment(item.created_at).format('DD-MM-YYYY HH:MM')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Skeleton animation="wave" width="80%" height={40} style={{marginBottom: 6}}/>
                          <Skeleton animation="wave" width="30%" height={40}/>
                        </>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
                </Link>
              </Grow>
    </>
  )
}

export default Cards
