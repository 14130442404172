import React from 'react'
import Typography from '@mui/material/Typography'
import "./footer.css"

const Footer = ({mairie}) => {
  return (
    <div className='footer'>
        <div className='footer-left'>
          <Typography className='footer-name'>
            © 2023 {mairie.libelle}
          </Typography>
        </div>
        <div className='footer-right'>
          <Typography className='footer-copy'>
             Développé par <a href='https:://local-click.fr'>Local-click</a> tout droit réservé
          </Typography>
        </div>
    </div>
  )
}

export default Footer
