import { Grid } from '@mui/material'
import React from 'react'
import "./contact.css"

const ContactMap = () => {
  return (
    <>
         <Grid item className='section-title mb-30'>
            <span></span>
            <h6  className='section-title-text'>Localisation</h6>
        </Grid>
        <Grid item xs={12} md={12} className="mtb_15 "> 
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24396.455763004884!2d-115.13108354428735!3d36.18912977254862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13:0xe22fdf6f254608f4!2sLos+Angeles,+Kaliforniya,+Birle%C5%9Fik+Devletler!5e0!3m2!1str!2str!4v1509525039851" height="350" title="carte adresse"   style={{border:0,width:"100%"}} allowFullScreen></iframe>
        </Grid>
    </>
  )
}

export default ContactMap
