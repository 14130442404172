const myCustomLocale = {
    // months list by order
    months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
    ],
  
    // week days by order
    weekDays: [
      
      {
        name: 'Lundi',
        short: 'L',
      },
      {
        name: 'Mardi',
        short: 'M',
      },
      {
        name: 'Mercredi',
        short: 'M',
      },
      {
        name: 'Jeudi',
        short: 'J',
      },
      {
        name: 'Vendredi',
        short: 'V',
      },
      {
        name: 'Samedi',
        short: 'S',
        isWeekend: true,
      },
      {
        name: 'Dimanche', // used for accessibility 
        short: 'D', // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      }
    ],
  
    // just play around with this number between 0 and 6
    weekStartingIndex: 6,
  
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },
  
    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },
  
    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },
  
    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },
  
    // texts in the date picker
    nextMonth: 'Mois Suiv',
    previousMonth: 'Mois Prec',
    openMonthSelector: 'Sélecteur de mois ouvert',
    openYearSelector: 'Sélecteur d\'année ouverte',
    closeMonthSelector: 'Fermer le sélecteur de mois',
    closeYearSelector: 'Fermer le sélecteur d\'année',
    defaultPlaceholder: 'Selectinner...',
  
    // for input range value
    from: 'de',
    to: 'à',
  
  
    // used for input value when multi dates are selected
    digitSeparator: ',',
  
    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,
  
    // is your language rtl or ltr?
    isRtl: false,
  }

  export default myCustomLocale;