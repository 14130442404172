import React from 'react'
import Typography from '@mui/material/Typography'
import './profile.css'
import CustomTimeline, {CustomTimelineSeparator} from '../Timeline/CustomTimeline'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { TimelineContent, TimelineItem } from '@mui/lab';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CutomButton from '../Button/CutomButton'
import ApprovalIcon from '@mui/icons-material/Approval';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';
import LOGODEFAULT from "../../assets/images/logomairie2023.png"


const CustomTimelineItem = ({title, text, link}) => (
  <TimelineItem>
      <CustomTimelineSeparator/>
      <TimelineContent className='timeline-content'>
        {link ? (
          <Typography className='timelineItem-text'>
            <span>{title} </span> <a href={link} target='_blank' rel="noreferrer">{text}</a>
          </Typography>
        ) : (
          <Typography className='timelineItem-text'>
            <span>{title} </span> {text}
            </Typography>)
        }
      </TimelineContent>
  </TimelineItem>
)

const Profile = ({mairie}) => {
  return (
    <div className='profile container-shadow'>
        <div className='profile-name'>
            <h1 className='profile-text fz-30'>Mairie de { mairie.libelle }</h1>
        </div>

        <figure className='profile-image'>
          <img src={(mairie.logo) ?  mairie.logo : LOGODEFAULT} alt="logo mairie" />
        </figure>

       <div className='profile-information'>
            <CustomTimeline icon={<LocalLibraryOutlinedIcon/>}>
              <CustomTimelineItem  title={<ApprovalIcon/>} text={mairie.code}/>
              <CustomTimelineItem title={<LocalPhoneIcon/>} text={mairie.phone}/>
            </CustomTimeline>

            <div className='button-container'>
               <Link to='/contact'> 
                <CutomButton text={'En savoir plus'} icon={<ArrowCircleRightIcon fontSize='medium'/>}/>
              </Link>
            </div>
        </div>
    </div>
  )
}

export default Profile
