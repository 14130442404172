import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Parser from 'html-react-parser';
import Image from 'mui-image'
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "./reservationImage.css"
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import GroupIcon from '@mui/icons-material/Group';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReadMore from '../Benefits/ReadMore';
import CalendarReservation from './CalendarReservation';
import COUVERTURE_SALLE from "../../assets/images/salle-inconnu.webp"
import { useEffect } from 'react';
import axios from 'axios';

const MoreReservation = ({ salle ,url}) => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [dateReserver, setDateReserver] = useState([])
    useEffect(() => {
      axios.get(`${url}/reservation/${salle.id}`)
      .then((response) => {
          setDateReserver(response.data);
          setIsLoading(true)
        })
      .catch(erreur => {
          setError(erreur);
          setIsLoading(true)
        });
    },[])

  return (
    <>
      <Grid container className="section pt-45">
      {(Object.keys(salle.salle_images).length > 0) ?
          <>
            <Grid item sm={12} md={6} style={{ minHeight: "350px" }}>
              <div className='containe'>
                <Image className='cursor-pointe' onClick={() => setOpen(true)} src={salle.couverture} />
                <button className='btn' onClick={() => setOpen(true)} type="button"><InsertPhotoIcon /> Voir les photos</button>
              </div>
            </Grid>
            <Grid item sm={12} md={6} className='img-autre'>
              <Grid container style={{ minHeight: "350px", paddingLeft: "10px" }}>
                {salle.salle_images.slice(0, 4).map((item, index) => (
                  <Grid key={index} item style={{ padding: "0 5px 5px 0" }} sm={12} md={6}>
                    <Image onClick={() => setOpen(true)} className='cursor-pointer' src={item.src} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
          :
          <Grid item sm={12} md={12} style={{ maxHeight: "350px" }}>
            <Image className='cursor-pointe' onClick={() => setOpen(true)} src={salle.couverture ? salle.couverture : COUVERTURE_SALLE} />
          </Grid>
        }

        <Grid item sm={12} md={12} className='pt-35'>
          <div className='flexBetween'>
            <button className='button-caract'>
              <SquareFootIcon sx={{ mr: 1 }} fontSize="large" color="primary" />
              {salle.surface} m²
            </button>
            <button className='button-caract'>
              <GroupIcon sx={{ mr: 1 }} fontSize="large" color="primary" />
              {salle.nombrePlace} personnes
            </button>
            <button className='button-caract'>
              <CreditCardIcon sx={{ mr: 1 }} fontSize="large" color="primary" />
              Dès {salle.prixNonResident} €
            </button>
          </div>
          <div className='description'>
            {/*<ReadMore>
                {Parser(salle.description)}
            </ReadMore>*/}
             {Parser(salle.description)}
          </div>
        </Grid>

        <CalendarReservation salleId={salle.id} dateReserver={dateReserver}/>
         {/*<CalendarCustom/>*/}
        
      </Grid>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={Array.from(salle.salle_images)}
        plugins={[Counter]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
      />
    </>
  )
}

export default MoreReservation
