import React, { useEffect, useState } from 'react'
import MoreReservation from '../../components/Reservation/MoreReservation'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ReservationDetail = ({url}) => {

  const id = useParams()
  
  const [isLoading, setIsLoading] = useState(false);
  const [salle, setSalle] = useState([]);
  const [error, setError] = useState(null);
  

  useEffect(() => {
      axios.get(`${url}/salle/one/${id.id}`)
      .then((response) => {
          setSalle(response.data);
          setIsLoading(true)
        })
      .catch(erreur => {
          setError(erreur);
          setIsLoading(true)
        });
         
  }, []);

  if (error) return `Error: ${error.message}`;

  return (
    <>
    {isLoading ?
       <MoreReservation salle={salle} url={url}/> 
       : "Chargement en cours"}
    </>
  )
}

export default ReservationDetail
