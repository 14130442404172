import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import "../reservationImage.css";
import "../option.css";
import { Grid } from "@mui/material";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import projecteur from "../../../assets/images/projecteur.png";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import "./table.css"

const ReservationForm = ({
    options,
    addOption,
    optionChoice,
    addReservation,
    dateChoisi,
    deleteReservation,
    chooseType,
    infoSalle,
    mairie,
    loading
    }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [type, setType] = useState("")
    const [dateReservation, setDateReservation] = useState([])
    const [validated, setValidated] = useState(false);


    const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSuivant = () => {
       if(dateRec === ""){
            setvalidDateRec(true)
        } else if(timeRec === ""){
            setvalidTimeRec(true)
        } else {
            setvalidDateRec(false)
            setvalidTimeRec(false)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /**
     * Count number of true value in date
     */

    function numberOfTrueValues(obj) {
        var counter = 0;
        for (var index = 0; index < obj.length; index++) {
           if (obj[index].week === true) {
              counter++;
           }
        }
        return counter;
     }

    /**
     * Delete date from reservation
     * @param {*} id 
     */
    const deleteDataLocal = (id) => {
        deleteReservation(id);
    }

    /**
   * DEFINE STATE
   */

    
    /**
     * *********** Validate data and get data starttime *************
     */
    const [validStartTime, setValidStartTime] = useState(false)
    const [startTime, setStartTime] = useState("")
    const handleChangeStart = event => {
        setStartTime(event.target.value);
        setValidStartTime(false)
    };

    /**
     * *********** Validate data and get data endTime *************
     */
    const [validEndTime, setvalidEndTime] = useState(false)
    const [endTime, setEndTime] = useState("")
    const handleChangeEnd = event => {
        setEndTime(event.target.value);
        setvalidEndTime(false)
    };

    /**
     * OPEN AND CLOSE PANEL
     * @param {*} panel
     */

    /**
     * ADD OPTION TO RESERVATION
     */
    const [arrayOption, setArrayOption] = useState([]);
    const handleClick = (id, libelle, prix) => {
        addOption({ id: id, libelle: libelle, prix: prix });
    };


    useEffect(() => {
        window.scrollTo(0, 0)
        setArrayOption(optionChoice);
    }, [arrayOption, optionChoice]);
   

     /**
     * *********** Date et heure de recupération clé *************
     */
     const [validDateRec, setvalidDateRec] = useState(false)
     const [dateRec, setDateRec] = useState("")
     const handleChangeDateRec = event => {
         setDateRec(event.target.value);
         setvalidDateRec(false)
     };

     const [validTimeRec, setvalidTimeRec] = useState(false)
     const [timeRec, setTimeRec] = useState("")
     const handleChangeTimeRec = event => {
         setTimeRec(event.target.value);
         setvalidTimeRec(false)
     };

    /**
     * VALIDATE DATA FORM
     */

    const onChangeType = (value) => {
        setType(value);
        chooseType(value)
    };
    const [formData, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        code: "",
        adresse: "",
    });
    const onChange = (e) => {
        setValues({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setDateReservation([])
            dateChoisi.map((date) => {
                dateReservation.push({
                    day: date.day,
                    month: date.month,
                    year: date.year,
                    week: ((numberOfTrueValues(dateChoisi) > 1 && date.week) ? 'Weekend' : 'Ordinaire'),
                    prix: ((numberOfTrueValues(dateChoisi) > 1 && date.week) ? infoSalle.prixWeekend : (type === "Résident" ? infoSalle.prixResident : infoSalle.prixNonResident))
                })  
            })
            const data = {
                user: formData,
                option: arrayOption,
                type: type,
                dateRec:dateRec,
                timeRec:timeRec,
                date: dateReservation,
                salle:{
                    id: infoSalle.id,
                    image: infoSalle.couverture,
                    nom: infoSalle.nom
                },
                mairie: mairie
            }
        const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                } else{
                    addReservation(data);
                    setValidated(true);
                }
        
    };

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation="vertical">

                <Step key="reservation">
                    <StepLabel>
                        <span className="fz-18">Votre Réservation</span>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label className="fz-12">Qui êtes vous ?</Form.Label>
                                    <Form.Select
                                        value={type}
                                        onChange={(e) => onChangeType(e.currentTarget.value)}
                                    >
                                        <option value="">-- sélectionnez --</option>
                                        <option value="Résident">Résident</option>
                                        <option value="Non résident">Non résident</option>
                                    </Form.Select>
                                </Form.Group>
                            </Grid>
                            {type && (
                                <>
                                <Grid item xs={12} md={12}>
                                {dateChoisi.length !== 0 ? (
                                    <><Form.Label className="fz-12 mb-2">Choix de réservation</Form.Label>
                                         <table>
                                         <thead>
                                             <tr>
                                                 <th scope="col">#</th>
                                                 <th scope="col">Date</th>
                                                 <th scope="col">Tarif</th>
                                                 <th scope="col">Montant</th>
                                                 <th scope="col"></th>
                                             </tr>
                                         </thead>
                                         <tbody>
                                             {
                                                 dateChoisi.map((date, index) => (
                                                     <tr key={index}>
                                                         <td>{date.id}</td>
                                                         <td data-label="Date">{`${date.day}/${(date.month > 9) ? date.month : '0'+date.month}/${date.year}`}</td>
                                                         <td data-label="Tarif">
                                                            {(numberOfTrueValues(dateChoisi) > 1 && date.week) ? "Weekend" : type}
                                                         </td>
                                                         <td data-label="Montant">
                                                         {(numberOfTrueValues(dateChoisi) > 1 && date.week) ? infoSalle.prixWeekend : (type === "Résident" ? infoSalle.prixResident : infoSalle.prixNonResident)}
                                                         </td>
                                                         <td><DeleteIcon onClick={() => deleteDataLocal(date.id)} color="error"/></td>
                                                     </tr>
                                                 ))
                                                 
                                             }
                                         </tbody>
                                        </table>
                                        </>
                                ) : (
                                    <div className="flexCenter">
                                            <Link to={"/reservation-detail/"+infoSalle.slug_salle} className="custom-btn mt-3" >
                                                <span className="btn-text">Réserver une date</span>
                                            </Link>
                                    </div>
                                )}
                               
                                </Grid>
    
                              </>
                            )}
                            
                            {/*timeTwo && start && timeOne ? (*/}

                            {/* ) : null */}
                        </Grid>
                        {type && dateChoisi.length !== 0 ? (
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <button
                                        className="custom-btn mt-3"
                                        onClick={handleNext}
                                    >
                                        <span className="btn-text">Suivant</span>
                                    </button>
                                </div>
                            </Box>
                        ) : null}
                        
                    </StepContent>
                </Step>
                {options.length !== 0  ? (
                    <Step key="besoin">
                        <StepLabel className="fz-18"
                            optional={(<Typography variant="caption">L'ajout d'option sur votre réservation est optionnel</Typography>)}>
                            <span className="fz-18">Vos besoins</span>
                        </StepLabel>
                        <StepContent>
                            <Grid container spacing={4}>
                                {options?.map((option, index) => (
                                    <Grid key={index} item xs={12} sm={12} md={6}>
                                        <div
                                            className={`option ${arrayOption.some((item) => item.id === option.id) && "checked"
                                                }`}
                                            onClick={() =>
                                                handleClick(
                                                    option.id,
                                                    option.libelle,
                                                    option.salle_options.prix
                                                )
                                            }
                                        >
                                            <div className="option-icone">
                                                <img src={projecteur} alt="icone video projecteur" />
                                            </div>
                                            <div className="option-text">
                                                <h3 className="option-title">{option.libelle}</h3>
                                                <span className="option-prix">
                                                    {option.salle_options.prix}€
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}

                            </Grid>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <button
                                        className="custom-btn mt-3"
                                        onClick={handleNext}
                                    >
                                        <span className="btn-text">Suivant</span>
                                    </button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ ml: 2 }}
                                    >
                                        Précèdent
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ) : null}
                <Step key="heure">
                        <StepLabel className="fz-18"
                            optional={(<Typography variant="caption">Veuillez choisir le jour et l'heure à laquelle vous souhaiteriez récupérer les clés.</Typography>)}>
                            <span className="fz-18">Remise des clés</span>
                        </StepLabel>
                        <StepContent>
                            <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                            <Form.Group  controlId="validationCustom01">
                                                <Form.Label className="fz-12">Date</Form.Label>
                                                <Form.Control
                                                    className={validDateRec ? 'errorInput' : ''}
                                                    required
                                                    type="date"
                                                    value={dateRec}
                                                    onChange={handleChangeDateRec}
                                                />
                                                {validDateRec ? (<small className="textError mt-1">Obligatoire.</small>) : ""}
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Form.Group  controlId="validationCustom01">
                                                <Form.Label className="fz-12">Heure</Form.Label>
                                                <Form.Control
                                                    className={validTimeRec ? 'errorInput' : ''}
                                                    required
                                                    type="time"
                                                    value={timeRec}
                                                    onChange={handleChangeTimeRec}
                                                />
                                                    {validTimeRec ? (<small className="textError mt-1">Obligatoire.</small>) : ""}
                                                </Form.Group>
                                        </Grid>
                            </Grid>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <button
                                        className="custom-btn mt-3"
                                        onClick={handleSuivant}
                                    >
                                        <span className="btn-text">Suivant</span>
                                    </button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ ml: 2 }}
                                    >
                                        Précèdent
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                <Step key="contact">
                    <StepLabel className="fz-18" optional={(<Typography variant="caption">Merci de renseigner vos informations personnelles</Typography>)}>
                        <span className="fz-18">Vos coordonnées</span>
                    </StepLabel>
                    <StepContent>
                        {/* FORM */}

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Card className="p-3">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label className="fz-12">Prénom & Nom</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            onChange={onChange}
                                            defaultValue={formData.name === null ? "" : formData.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label className="fz-12">Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="email"
                                            onChange={onChange}
                                            defaultValue={formData.email === null ? "" : formData.email}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                                        <Form.Label className="fz-12">Téléphone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="phone"
                                            onChange={onChange}
                                            defaultValue={formData.phone === null ? "" : formData.phone}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label className="fz-12">Code Postale</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="code"
                                            onChange={onChange}
                                            defaultValue={formData.code === null ? "" : formData.code}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="8" controlId="validationCustom05">
                                        <Form.Label className="fz-12">Adresse</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="adresse"
                                            onChange={onChange}
                                            defaultValue={formData.adresse === null ? "" : formData.adresse}
                                        />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3 mt-2">
                                    <Form.Check
                                        className="fz-13"
                                        required
                                        label="En cliquant sur Envoyer la demande de réservation, j'accepte les conditions générales d'utilisation"
                                        feedbackType="invalid"
                                    />
                                </Form.Group>
                            </Card>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <button type="submit"
                                        className="custom-btn mt-3"
                                    >
                                        <span className="btn-text">Envoyer la demande</span>
                                        {loading && (
                                            <CircularProgress
                                                size={48}
                                                sx={{
                                                position: 'absolute',
                                                top: -6,
                                                left: -6,
                                                zIndex: 1,
                                                }}
                                            />
                                            )}
                                    </button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ ml: 2 }}
                                    >
                                        Précèdent
                                    </Button>
                                </div>
                            </Box>
                        </Form>

                        {/* end FORM */}

                    </StepContent>
                </Step>

            </Stepper>
            {/*activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )*/}
        </Box>
    );
}

export default ReservationForm
