import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Grow,
  Skeleton,
  Tab,
  Tabs,
} from "@mui/material";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { ModalDialog, ModalOverflow } from "@mui/joy";
import Image from 'mui-image'
import "./calendrier.css";
import resumeData from "../../utils/resumeData";


const Calendrier = () => {
  const [tabValue, setTabValue] = useState("Tout");
  const [projectDialog, setProjectDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container spacing={1} className="section pb-45 pt-45">
      {/* Title */}
      <Grid item className="section-title mb-30">
        <span></span>
        <h6 className="section-title-text">Calendrier des évènements</h6>
      </Grid>

      {/* Tabs and Content Container*/}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          indicatorColor="white"
          className="customs-tabs"
          onChange={(e, newValue) => setTabValue(newValue)}
        >
          <Tab
            label="Tout"
            value="Tout"
            className={
              tabValue === "Tout" ? "customs-tabs active" : "customTabs-item"
            }
          />
          {[...new Set(resumeData.portofolio.map((item) => item.type))].map(
            (type) => (
              <Tab
                label={type}
                value={type}
                key={type}
                className={
                  tabValue === type ? "customs-tabs active" : "customTabs-item"
                }
              />
            )
          )}
        </Tabs>
      </Grid>

      {/* projects */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {resumeData.portofolio.map((item,index) => (
            <>
              {tabValue === item.type || tabValue === "Tout" ? (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <Grow in timeout={1000}>
                    <Card
                      className="customCard"
                      onClick={() => setProjectDialog(item)}
                    >
                      <CardActionArea>
                        {isLoading ? (
                          <CardMedia
                            className="customCard-image"
                            image={item.image}
                            title={item.name}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangle"
                            animation="wave"
                            width={350}
                            height={140}
                          />
                        )}
                        <CardContent>
                          {isLoading ? (
                            <>
                            <Divider>{item.type}</Divider>
                              <Typography
                                variant={"body2"}
                                className="customCard-title"
                              >
                                {item.name}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Skeleton
                                animation="wave"
                                width="80%"
                                height={40}
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                width="30%"
                                height={40}
                              />
                            </>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Grid>
      
      {/* */}
     {projectDialog &&                       
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={projectDialog}
        onClose={() => setProjectDialog(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        
      >
       <ModalOverflow>
           <ModalDialog layout="fullscreen" color="primary" style={{ display: 'flex', alignItems: 'center' }}>
              <ModalClose variant="outlined" />
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'30px 0',maxWidth:'600px'}}>
                <Grid item>
                <Typography className="calendar-titre" style={{ marginBottom:'10px'}}>{projectDialog.name}</Typography >
                <Typography variant="body2" className='calendar-date' style={{ marginBottom:'20px'}}>{projectDialog.start} au {projectDialog.end}</Typography>
                <Image src={projectDialog.image}  height="60vh" />
                <Typography variant="body1" style={{ marginTop:'20px',textAlign:'justify'}}>{projectDialog.content}</Typography>
                <Alert variant="outlined" style={{ marginTop:'20px'}}>Nombre de place maximal autorisé : {projectDialog.nbrMax}</Alert>
                </Grid>
              </div>
             
    
          </ModalDialog>
      </ModalOverflow>
    
      </Modal>
}
      {/* Détails projects 
      <Dialog fullScreen='true'
        className="projectDialog"
        open={projectDialog}
        onClose={() => setProjectDialog(false)}
      >
        <DialogTitle onClose={() => setProjectDialog(false)}>
          {projectDialog.title}
        </DialogTitle>
        <img src={projectDialog.image} alt="" className="projectDialog-image" />
        <DialogContent>
          <Typography className="projectDialog-description">
            {projectDialog.description}
          </Typography>
        </DialogContent>
        <DialogActions className="projectDialog-actions">
          {projectDialog?.links?.map((link) => (
            <a href={link.link} className="projectDialog-icon">
              {link.icon}
            </a>
          ))}
        </DialogActions>
      </Dialog>*/}
    </Grid>
  );
};

export default Calendrier;
